import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Dropdown } from "react-bootstrap";
import LogoHeader from "@/assets/svgfiles/header/logoHeader";
import Select from "@/assets/svgfiles/header/Select";
import tsIcon from "@/assets/svgfiles/header/headPic.png";

import { Image, message, Space, Empty, Button, Spin } from "antd";

import donorAPI from "@/api/app/donor/donor";
import errorDeal from "@/utils/errorDeal";
import StepsPanel from "@/components/panel/StepsPanel";
import items from "@/pad/pages/donor/create/resource/Steps";
import urlUtils from "@/utils/urlUtils";
import { firstToUpper } from "@/components/util/Util";
import { useNavigate, useLocation } from "react-router-dom";
import StepCreateProfile from "../donor/process/createProfile/stepCreateProfile/stepCreateProfile";
import { useDispatch, useSelector } from "react-redux";
import MessageConfirmModal from "@/components/messageModal/MessageConfirmModal";
import { IS_CHANGE } from "@/api/employee/components/state/actions/isChange";
import { getDonorDetail } from "@/components/service/visitor/visitor";
import "@/styles/pages/createdonor/literacyCheck.css";
import SiderProcess from "@/pad/assets/svgicons/aside/SiderProcess";

const CDonorLayout = ({ children, footer, current, noOverflowY, data, setData }) => {
  const location = useLocation();
  const user = useSelector((state) => state.authReducer)
  const {visitor_user} = user;
  let donorId = visitor_user ? visitor_user.donorId : "";
  const currentStep =
    current && current >= 0 && current < items.length ? current : 0;
  const [donorInfo, setdonorInfo] = useState(visitor_user ? visitor_user : null);
  const [isloading, setisloading] = useState(false);
  const dispatch = useDispatch()
  const isChange = useSelector((state) => state.isChange.isChange);
  const messageModalRef = useRef("rememberMe");
  const navigate = useNavigate();

  let currentNavEntries = window && window?.navigation && window?.navigation?.entries() ? window?.navigation?.entries().filter(it => {
    const url = new URL(it.url);
    it.pathname = url.pathname
    return it.pathname !== window.location.pathname
  }) : [];

  useEffect(() => {
    let onpaste = null
    if (isChange) {
      onpaste = (event) => {
        messageModalRef.current?.showWarningConfirm(
          <p style={{textAlign: 'center'}} >Are you sure you want to leave without saving?</p>,
          () => {
            dispatch({ type: IS_CHANGE, isChange: false })
            navigate(currentNavEntries[0].pathname);
          },
          ""
        );
      }
      window.addEventListener('popstate', onpaste, false);
    }
    if (!isChange) {
      return () => {
        isChange && window.removeEventListener("popstate", onpaste);
      }
    }
  }, [isChange]);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      if (isChange) {
        return;
      }
      e.returnValue = true;
    };

    if (isChange) {
      window.removeEventListener("beforeunload", handler)
      window.addEventListener("beforeunload", handler);
    } else {
      window.removeEventListener("beforeunload", handler)
      window.addEventListener("beforeunload", handler);
      window.removeEventListener("beforeunload", handler)
    }
    return () => window.removeEventListener("beforeunload", handler);
  }, [isChange]);

  useEffect(() => {
    dispatch({ type: IS_CHANGE, isChange: false })
  }, [])
  
  const getDonor = async () => {
    if (donorId) {
      await getDonorDetail({ donorId: donorId })
      .then((resultData) => {
        if(resultData.status == 200 && resultData.data) {
          setData && setData(resultData.data)
          // setdonorInfo(resultData.data)
        }
      }).catch((error) => {
        setisloading(false);
        message.error("There is no such donor");
      })
    }
  }
  
  React.useEffect(() => {
    donorId && getDonor();
  }, [donorId]);

  return (
    <div
      className="d-flex flex-column"
      style={{
        height: `100%`,
        overflowY: "hidden",
        width: `100%`,
        backgroundColor: `rgba(242, 243, 248, 1)`,
        fontFamily: "Montserrat",
        justifyContent: "flex-start",
      }}
    >
      <div className="d-flex flex-row align-items-center justify-content-between div-main-header">
        <div className="d-flex">
          <Image
            width={150}
            height={35}
            style={{
              marginLeft: "1rem",
              width: "9.375rem",
              height: "2.8125rem",
            }}
            src={process.env.REACT_APP_PURPLE_LOGIN}
            preview={false}
          />
          <div className="row w-100">
            <div className="col-12">
              <div
                className="room_processing_style d-flex justify-content-center align-items-center"
                style={{ marginLeft: "2rem" }}
              >
                <span className="d-flex me-2">
                  <SiderProcess />
                </span>
                <span style={{whiteSpace:"nowrap"}}>Create new donor</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-row align-items-center"
          style={{
            fontFamily: "Montserrat",
            marginRight: `1.5rem`,
            marginLeft: `1.5rem`,
            overflow:"auto"
          }}
        >
          <Space size="large">
            <div className="text-header"  style={{whiteSpace:"nowrap"}}>Donor Name:</div>
            <div className="text-header1"  style={{whiteSpace:"nowrap"}}>
              {donorInfo
                ? firstToUpper(donorInfo.firstName) +
                  " " +
                  firstToUpper(donorInfo.lastName)
                : "N/A"}
            </div>
            <div className="text-header"  style={{whiteSpace:"nowrap"}}>Donor ID:</div>
            <div className="text-header1"  style={{whiteSpace:"nowrap"}}>
              {donorInfo ? donorInfo.donorId : "N/A"}
            </div>
          </Space>
        </div>
      </div>
      <div
        style={{
          height: `calc(-6.375rem + 100vh)`,
          overflow: "hidden",
        }}
      >
        <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center- justify-content-end div-content pb-3">
          {donorInfo ? (
            <>
              <StepCreateProfile current={currentStep} items={items} />

              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  background: "#FFFFFF",
                  paddingBottom: footer ? "30px" : "30px",
                  display: "flex",
                  alignContent: "space-between",
                  flexWrap: "wrap",
                }}
                className="div-cus-content"
              >
                <div
                  style={
                    noOverflowY
                      ? { height: footer ? `calc(100% - 4rem)` : "100%" }
                      : {
                          height: footer ? `calc(100% - 4rem)` : "100%",
                          overflowY: "scroll",
                        }
                  }
                  className="w-100"
                >
                  {children}
                </div>

                {footer && (
                  <div
                    className="w-100 video_content"
                    style={{ height: "50px" }}
                  >
                    {footer}
                  </div>
                )}
              </div>
            </>
          ) : isloading ? (
            <Spin size="large">
              <div className="content" />
            </Spin>
          ) : (
            <Empty
              imageStyle={{
                height: 60,
              }}
              description={<span>There is no such donor</span>}
            ></Empty>
          )}
        </div>
      </div>
      <MessageConfirmModal
        textCentered
        widthBtn={`auto`}
        noTitle={`No, Keep Stay`}
        yesTitle={`Yes, Leave without Saving`}
        ref={messageModalRef}
      />
    </div>
  );
};

export default CDonorLayout;
