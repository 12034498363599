import React, { useEffect, useRef, useState } from 'react';
import DownTimeMillisecond from '@/pad/components/donor/process/createProfile/detailsPage/DownTimeMillisecond';
import MessageConfirmModal from '@/components/messageModal/MessageConfirmModal';

const VisitWSPage = ({ handleBackHome }) => {
    const [timeLeft, setTimeLeft] = useState(180); // 180 seconds as initial time
    const timerRef = useRef(null);
    const messageModalRefCount = useRef("");

    const resetTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        setTimeLeft(180); // Reset countdown time
    };

    const startTimer = () => {
        timerRef.current = setInterval(() => {
            setTimeLeft((prevTimeLeft) => {
                if (prevTimeLeft <= 1) {
                    clearInterval(timerRef.current);
                    handleTimeUp();
                    return 0;
                }
                return prevTimeLeft - 1;
            });
        }, 1000); // Decrement every second
    };

    const handleTimeUp = () => {
        // When time is up, show the modal
        messageModalRefCount.current.showWarningConfirmsAutoCloseTime(
            <div>
                <div>{"You have not fully completed the new donor process, please go to the reception to finish the process before your donation."}</div>
                <div className="pt-4" style={{color:"rgba(0, 117, 255, 1)"}}>
                    ( <DownTimeMillisecond handlerOnClickNext={handleBackHome} timeTotal={timeLeft} />s )
                </div>
            </div>,
            () => { handleBackHome && handleBackHome(); },
            "",
            true,
            () => { handleBackHome && handleBackHome(); },
            180000 // 180 seconds for auto-close
        );

        // Reset timer after modal closes (could also be done in modal's auto-close logic)
        resetTimer();
        startTimer();
    };

    const handleUserActivity = () => {
        resetTimer();
        startTimer();
    };

    useEffect(() => {
        // Start the timer on component mount
        startTimer();

        // Attach event listeners for user activity
        window.addEventListener('click', handleUserActivity);
        window.addEventListener('keypress', handleUserActivity);

        return () => {
            // Cleanup event listeners on component unmount
            window.removeEventListener('click', handleUserActivity);
            window.removeEventListener('keypress', handleUserActivity);

            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return (
        <div>
            <MessageConfirmModal textCentered ref={messageModalRefCount} />
            {/* You can display the remaining time if needed */}
            {/* <p>Time Left: {timeLeft}s</p> */}
        </div>
    );
};

export default VisitWSPage;
